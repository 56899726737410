import React from 'react';
import { Link } from 'gatsby';

// components
import Layout from '../components/layout';

const PriceListPage = () => {
  return(
    <Layout>
      <div className="container__box">
        <h1><span class="heading__naples">Cennik</span></h1>
        <p>Poniżej znajdziecie Państwo cennik przykładowych usług serwisowych.</p>
      </div>
      <div className="container__graybox">
        <h2>Ważna informacja</h2>
        <p>Cennik ma charakter poglądowy, nie świadczy o tylko i wyłącznie pełnionych usługach. W cenniku znajdują się najczęściej wykonywane prace nad sprzętem komputerowym, by w pełni uzyskać koszt niestandardowej usługi, prosimy o <Link to="/contact">kontakt</Link>.</p>
      </div>
      <div className="container__table">
        <table>
          <thead>
            <tr>
              <th>Usługa</th>
              <th>Cena</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Diagnoza</td>
              <td>0</td>
            </tr>
            <tr>
              <td>Regeneracja układu chłodzenia CPU/GPU</td>
              <td>30</td>
            </tr>
            <tr>
              <td>Wymiana i kopia dysku</td>
              <td>30</td>
            </tr>
            <tr>
              <td>Instalacja systemu, sterowników, aplikacji</td>
              <td>40</td>
            </tr>
            <tr>
              <td>Odzyskiwanie danych</td>
              <td>80</td>
            </tr>
            <tr>
              <td>Wymiana uszkodzonej matrycy</td>
              <td>50</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Layout>
  );
}

export default PriceListPage;